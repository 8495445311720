<template>
    <div class="createPromotionSpecialOffer">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form :model="form" inline size="medium" ref="fm">
                <div>
                    <el-form-item label="活动类型">
                        <el-input readonly v-if="form.rule === 4" value="件数折扣特价"></el-input>
                        <el-input readonly v-if="form.rule === 5" value="任意选一口价"></el-input>
                        <el-input readonly v-if="form.rule === 6" value="买满数量送赠品"></el-input>
                        <el-input readonly v-if="form.rule === 7" value="固定一口价"></el-input>
                    </el-form-item>
                </div>
                <div style="display: flex">
                    <el-form-item label="活动名称" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <el-form :model="form">
                <hr class="efHr" style="background-color: #ccc" />

                <el-form-item label="规则设置" size="mini" v-if="goodsType !== 3">
                    <template v-if="form.rule === 4">
                        <div style="margin-left: 70px" v-for="(ele, idx) in form.favour" :key="idx">
                            <span>购买商品数量满</span>
                            <el-input
                                readonly
                                style="margin: auto 10px; width: 60px"
                                :precision="0"
                                v-model="ele.count"
                            ></el-input>
                            件
                            <span>,该商品打</span>
                            <el-input
                                style="margin: auto 10px; width: 60px"
                                v-model="ele.discount"
                                :precision="1"
                                :readonly="true"
                            ></el-input>
                            折
                        </div>
                    </template>
                    <template v-if="form.rule === 6">
                        <div style="margin-left: 70px" v-for="(ele, idx) in form.favour" :key="idx">
                            <span>购买商品数量满</span>
                            <el-input
                                readonly
                                style="margin: auto 10px; width: 60px"
                                :precision="0"
                                v-model="ele.count"
                            ></el-input>
                            件
                            <span>,送</span>
                            <el-input v-model="ele.giftSkuName" readonly></el-input>
                            商品
                            <el-input
                                readonly
                                style="margin: auto 10px; width: 60px"
                                :precision="0"
                                v-model="ele.giftCount"
                            ></el-input>
                            件
                        </div>
                    </template>
                </el-form-item>
            </el-form>
            <div class="mb10">
                <span class="font16 bold">活动规则</span>
                <hr class="efHr" style="background-color: #ccc" />
                <el-form size="medium">
                    <el-form-item label="活动商品类型" v-if="form.rule != 5">
                        <el-input readonly v-if="goodsType === 1" value="全部商品"></el-input>
                        <el-input readonly v-if="goodsType === 2" value="指定商品不参加"></el-input>
                        <el-input readonly v-if="goodsType === 3" value="指定商品参加"></el-input>
                    </el-form-item>
                </el-form>
                <div class="flexBtw">
                    <div class="font14">
                        <el-form inline :model="form" size="medium" style="display: inline-block">
                            <el-form-item label="价格参考店面" style="width: 1200px">
                                <efn-group-dept-cascader
                                    size="medium"
                                    v-model="organization"
                                    :sel-all-dept="false"
                                ></efn-group-dept-cascader>

                                <span class="clr-textGray" style="margin-left: 40px"
                                    >下表零售价为价格参考店面的商品零售价格</span
                                >
                            </el-form-item>
                        </el-form>
                        <div style="margin: 5px" v-if="form.goods.length">
                            <span class="clr-pink"></span><span>{{ goodsType === 3 ? '' : '不' }}参加活动的商品</span>
                        </div>
                    </div>
                </div>
            </div>

            <el-table
                style="max-width: calc(100% - 90px); margin: auto 40px"
                size="mini"
                border
                :data="form.goods"
                v-if="form.goods.length || !form.allGoodsFlag"
            >
                <el-table-column type="index" label="No."></el-table-column>
                <el-table-column label="活动商品" min-width="220" prop="goodsName"></el-table-column>
                <el-table-column label="规格" width="140" prop="specs"></el-table-column>
                <el-table-column label="参考店面" min-width="220" prop="deptName"></el-table-column>
                <el-table-column label="参考零售价" width="140" prop="oriPrice">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.oriPrice.toFixed(2) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="参考活动价" width="140" v-if="form.rule === 7">
                    <template slot-scope="scope">
                        {{ (scope.row.price || 0).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="买满数量"
                    prop="count"
                    width="140"
                    v-if="!form.allGoodsFlag && (form.rule === 4 || form.rule === 6 || form.rule === 7)"
                >
                </el-table-column>
                <template v-if="!form.allGoodsFlag && form.rule === 4">
                    <el-table-column label="折扣(折)" width="140">
                        <template slot-scope="scope">
                            {{ !scope.row.oriPrice ? '' : (scope.row.discount || 0).toFixed(1) }}
                        </template>
                    </el-table-column>
                </template>
                <el-table-column label="活动单价" width="140" v-if="!form.allGoodsFlag && form.rule === 4">
                    <template slot-scope="scope">
                        {{ (scope.row.price || 0).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column label="赠品数量" width="140" v-if="form.rule == 6">
                    <template slot-scope="scope">
                        {{ scope.row.giftCount }}
                    </template>
                </el-table-column>
                <el-table-column label="赠送商品" width="240" v-if="form.rule == 6">
                    <template slot-scope="scope">
                        {{ scope.row.giftName }}
                    </template>
                </el-table-column>
            </el-table>
            <template v-if="form.rule === 5">
                <div style="margin-top: 30px" class="flexBtw mb10">
                    <div>
                        <span class="font14"><span class="clr-pink">* </span>活动规则</span>
                        <span class="clr-textGray font14" style="margin-left: 40px"
                            >购买任意件数以上商品按下表规则统一一口价</span
                        >
                    </div>
                </div>
                <el-table :data="form.favour" size="mini" border v-if="form.rule === 5">
                    <el-table-column label="活动商品买满数量(个/件)" width="180px" align="center" prop="count">
                    </el-table-column>
                    <el-table-column label="一口价(元)" width="120px" align="center" prop="price">
                        <template slot-scope="scope">
                            {{ (scope.row['price'] || 0).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column></el-table-column>
                </el-table>
            </template>
            <template v-if="form.rule === 7">
                <div style="margin-top: 30px">
                    <el-form :inline="true" size="medium">
                        <el-form-item label="合计零售总价">
                            <ef-price-input
                                :precision="2"
                                class="short"
                                v-model="sumFavour.total"
                                readonly
                            ></ef-price-input>
                        </el-form-item>
                        <el-form-item label="合计优惠" class="left40">
                            <ef-price-input
                                :precision="2"
                                class="short"
                                v-model="sumFavour.favour"
                                readonly
                            ></ef-price-input>
                        </el-form-item>
                        <el-form-item label="活动总价为原价" class="left40">
                            <ef-price-input
                                :precision="2"
                                class="short"
                                v-model="sumFavour.discount"
                                readonly
                            ></ef-price-input>
                            <span>折</span>
                        </el-form-item>
                        <br />
                    </el-form>
                    <el-form>
                        <div style="margin: 5px auto" v-for="(ele, idx) in form.favour" :key="idx">
                            <span>本套餐一口价</span>
                            <ef-price-input
                                style="margin: auto 10px; width: 85px"
                                v-model="ele.price"
                                :precision="2"
                                readonly
                            ></ef-price-input>
                            元
                        </div>
                    </el-form>
                </div>
            </template>

            <template v-if="form.rule == 8">
                <div style="margin-top: 30px; display: inline-flex">
                    <div>价格策略</div>
                    <div class="left10">
                        <el-form v-model="form">
                            <efn-radio-groups size="mini" :btns="meta.btns" v-model="form.allGoodsCountType">
                            </efn-radio-groups>
                        </el-form>
                    </div>
                </div>

                <efn-table size="medium" border :data="form.favour">
                    <el-table-column label="活动商品买满数量(个/件)" width="180px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.count }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="零售价统一折扣" width="120px" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.discount.toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button class="clr-green" type="text" @click="lineDetail(scope.row)"> 查看 </el-button>
                        </template>
                    </el-table-column>
                </efn-table>
                <fix-discount-dialog ref="fdd"></fix-discount-dialog>
            </template>
        </el-card>
    </div>
</template>

<script>
import MoneyUtil from 'js/MoneyUtils';
import { chain } from 'mathjs';
import MoneyUtils from 'js/MoneyUtils';
import EfnGroupDeptCascader from 'components/newStyle/EfnGroupDeptCascader';
import EfPriceInput from 'components/EfPriceInput';
import FixDiscountDialog from 'views/menu/promotion/np/component/FixDiscountDialog';
import EfnRadioGroups from 'components/newStyle/EfnRadioGroups';
import EfnTable from 'components/newStyle/EfnTable';

export default {
    name: 'NPromotionDetail',
    props: {
        promotionCode: {
            type: String,
            default: '',
        },
    },
    components: { EfnTable, EfnRadioGroups, EfPriceInput, EfnGroupDeptCascader, FixDiscountDialog },
    data() {
        return {
            c: chain,
            goodsType: 1,
            form: {
                code: '',
                name: '',
                groupCode: '',
                deptCode: '',
                deptName: '',
                allGoodsFlag: true,
                allGoodsCountType: 1, //1，单独计数，2：全场合计
                rule: 4,
                goods: [],
                favour: [],
            },
            meta: {
                btns: [
                    {
                        label: 1,
                        text: '件数倍数折扣',
                        tips: '例：任选3件9折，5件8折，购买12件活动商品，收银机中最先添加的10件商品8折，最后2件不打折',
                    },
                    {
                        label: 2,
                        text: '超出享受最高件数折扣',
                        tips: '例：任选3件9折，5件8折，购买12件活动商品，收银机中12件商品均打8折',
                    },
                ],
            },
            url: {
                save: '/npromotion/detail',
            },
        };
    },
    computed: {
        organization: {
            get() {
                return [this.form.groupCode, this.form.deptCode];
            },
            set() {},
        },
        sumFavour() {
            const _this = this;
            if (this.form.rule != 7) {
                return {
                    total: 0,
                    favour: 0,
                    discount: 0,
                };
            }
            const _fPrice = _this.form.favour[0].price || 0;
            const _g = _this.form.goods;
            const _total =
                _g.length > 1 ? _g.map((a) => (a['oriPrice'] || 0) * (a['count'] || 0)).reduce((a, b) => a + b) : 0;
            const _favour = _total - _fPrice;
            const _discount = _total && _fPrice ? (_fPrice * 10) / _total : 10;
            return {
                total: _total,
                favour: _favour,
                discount: _discount,
            };
        },
    },
    watch: {
        goodsType(value) {
            if (this.form.rule != 8) {
                this.form.allGoodsCountType = 1;
            }
            this.form.allGoodsFlag = value !== 3;
        },
    },
    activated() {
        const _this = this;
        //编辑
        this.$http.get(this.url.save + '/' + this.promotionCode).then((rsp) => {
            _this.form = rsp.data.data;
            _this.form.code = _this.promotionCode;
            _this.goodsType = _this.form.allGoodsFlag ? (_this.form.goods.length ? 2 : 1) : 3;
            _this.refreshGoodsList();
        });
    },
    methods: {
        refreshGoodsList() {
            this.form.goods = this.form.goods.map((r) => {
                r.price = MoneyUtil.moneyToYuan(r['price'] || 0);
                r.oriPrice = MoneyUtil.moneyToYuan(r['oriPrice'] || 0);
                return r;
            });
            this.form.favour = this.form.favour.map((f) => {
                f.price = MoneyUtils.moneyToYuan(f['price'] || 0);
                return f;
            });
            if (this.form.rule == 7) {
                //计算参考价
            }
        },
        lineDetail(favour) {
            this.$refs.fdd.show(favour, this.form.goods);
        },
    },
};
</script>

<style>
.createPromotionSpecialOffer {
    color: #606266;
}
</style>
