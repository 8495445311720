<template>
    <div>
        <el-dialog :visible.sync="visible" width="850px">
            <div class="mb10">
                <span>活动商品买满数量（个/件）</span
                ><el-input class="short" size="mini" :precision="0" readonly v-model="favour.count"></el-input>
                <span class="left50">零售价统一折扣</span
                ><el-input
                    :controls="false"
                    class="short left10"
                    size="mini"
                    :precision="2"
                    :readonly="readonly"
                    :value="favour.discount.toFixed(2)"
                ></el-input>
                <span class="left10">折</span>
            </div>
            <el-table :data="goods" size="medium" border>
                <el-table-column type="index" label="No."></el-table-column>
                <el-table-column min-width="220" label="活动商品" prop="sku.name">
                    <template slot-scope="scope">
                        {{ scope.row.goodsName }}
                    </template>
                </el-table-column>
                <el-table-column width="140" label="规格" prop="sku.specs">
                    <template slot-scope="scope">
                        {{ scope.row.specs }}
                    </template>
                </el-table-column>
                <el-table-column width="120" label="零售价" prop="price">
                    <template slot-scope="scope">
                        {{ scope.row.oriPrice.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column width="120" label="活动零售价" prop="price">
                    <template slot-scope="scope">
                        {{ ((scope.row.oriPrice * favour.discount) / 10).toFixed(2) }}
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="visible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'FixDiscountDialog',
    data() {
        return {
            visible: false,
            favour: {},
            goods: [],
        };
    },
    methods: {
        show(favour, goods) {
            this.visible = true;
            this.$set(this, 'favour', favour);
            this.$set(this, 'goods', goods);
        },
        hide() {
            this.visible = false;
            this.favour = {};
            this.goods = [];
        },
    },
};
</script>
